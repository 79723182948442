import { Injectable } from '@angular/core';

@Injectable()
export class AppSettingsService {
	constructor() {
	}

	get(key: string): any {
		let v = JSON.parse(localStorage.getItem(key));

		if (v) {
			if (v.expire) {
				let expire = new Date(v.expire);

				if (expire > new Date()) {
					return v.value;
				}
			} else {
				return v.value;
			}
		}

		this.remove(key);

		return null;
	}

	set(key: string, value: AppSetting) {
		localStorage.setItem(key, JSON.stringify(value));
	}

	remove(key: string) {
		localStorage.removeItem(key);
	}

	clear() {
		localStorage.clear();
	}
}

export class AppSetting {
	value: any
	expire?: Date
}

export class SettingKey {
	static readonly profile: string = "profile";
	static readonly sessionExpires: string = "sessionExpires";
}
