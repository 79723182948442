import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs';
import { flatMap, switchMap, map } from 'rxjs/operators';

import { ApiClientModule, ApiHeader, ApiRequest } from '../api-client/api-client.module';
import { ClientAuthResponse  } from '../api-client/interface';
import { AppLogService } from '../app-log/app-log.service';
import { AppSetting, AppSettingsService, SettingKey } from '../app-settings/app-settings.service';
import { CookieModule, CookieValue } from '../cookie/cookie.module';

@Injectable()
export class UserSessionService {
	constructor(
		private api: ApiClientModule,
		private log: AppLogService,
		private settings: AppSettingsService,
		private cookie: CookieModule
	) {
		this.getToken()
			.subscribe(
				_ => this.readyResolve(),
				err => this.log.ex("error looking up token", err)
			);
	}

	ready(): Promise<any> {
		return this.readyPromise;
	}

	getToken(): Observable<string> {
		let token = this.cookie.get(CookieValue.UserSession);

		if (token) {
			let renewAfter = new Date(this.settings.get(SettingKey.sessionExpires) || new Date());

			if (renewAfter <= new Date()) {
				return this.renewLease(token);
			} else {
				return of(token);
			}
		}

		return of(null);
	}

	secureRequest(req: ApiRequest): Observable<ApiRequest> {
		return this.getToken()
			.pipe( switchMap( t => this.api.authenticate(), (t, h) => { return { token: t, headers: h} } ) )
			.pipe( map( m =>
				req
					.addHeaders(m.headers)
					.addHeaders([{ name: "x-hat-auth", value: m.token }])
			));
	}

	private readyPromise: Promise<any> = new Promise<any>( res => this.readyResolve = res );
	private readyResolve: any;

	private renewLease(token: string): Observable<string> {
		let req: ApiRequest = new ApiRequest("/client/lease");

		return this.api.authenticate()
			.pipe(flatMap( (auth: ApiHeader[]) => {
				auth.push

				return this.api.get<ClientAuthResponse>(req.addHeaders(auth));
			} ))
			.pipe(flatMap( res => {
				let tokenExpiresOn = new Date(res.expiresOn);
				let sessionExpiresOn = new Date(res.expiresOn);

				let sessionExpire: AppSetting = {
					value: new Date(sessionExpiresOn.setDate(sessionExpiresOn.getDate() - 1)),
					expire: tokenExpiresOn,
				};

				this.settings.set(SettingKey.sessionExpires, sessionExpire);
				this.cookie.set(CookieValue.UserSession, res.token, {expires: tokenExpiresOn.toUTCString()});

				return of(res.token)
			}));
	}
}