import { HttpClientModule, HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { ApiClientModule } from './api-client/api-client.module';
import { AppLogModule } from './app-log/app-log.module';
import { AppSettingsModule } from './app-settings/app-settings.module';
import { I18nModule, Translations } from './i18n/i18n.module';
import { CookieModule } from './cookie/cookie.module';
import { UserSessionModule } from './user-session/user-session.module';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    HttpClientModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: { provide: TranslateLoader, useFactory: (http: HttpClient) => new TranslateHttpLoader(http, 'assets/i18n/'), deps: [HttpClient] }
    }),
    I18nModule,
    AppLogModule.forRoot(),
    AppSettingsModule.forRoot(),
    UserSessionModule.forRoot(),
  ],
  providers: [
  	ApiClientModule,
    CookieModule,
    StatusBar,
    SplashScreen,
    Translations,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
