import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AppSettingsService } from './app-settings.service';


@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class AppSettingsModule {
	static forRoot(): ModuleWithProviders {
		return {
			ngModule: AppSettingsModule,
			providers: [AppSettingsService]
		}
	}
}

export class ApiSettings {
	static readonly host: string = "https://staging-api.hattitude.net";
	static readonly sdkVer: string = "current";
	static readonly clientKey: string = "hatclientapp";
	static readonly clientSecret: string = "54c2d7f5ef21910ea9d53522abbe6f76";
}

export const EnableTrace = true;
export const DefaultAvatarPhoto = "/assets/img/avatar.png";